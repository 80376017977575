<template>
    <div class="rt-wraper">
      <h3>Copyright Permission</h3><p>Extracts from the document 'Anti-Money Laundering and Counter-Terrorist Finance (Guidance for the Accountancy Sector) May 2022 are used by kind permission of the publishers: CCAB Ltd. PO Box 433, Moorgate Place, London, EC2P 2BJ United Kingdom</p>
      <br><br>
      <p><a href="/terms-and-conditions" target="_blank" class="btn btn-primary btn-download">Terms & Conditions</a></p>
      <br><br>
      <p><a href="/privacy-policy" target="_blank" class="btn btn-primary btn-download">Privacy Policy</a></p>
      <br><br>
        <p><a href="/data-storage-archiving-policy" target="_blank" class="btn btn-primary btn-download">Data Storage & Archiving Policy</a></p>
      <br><br>
        <p><a href="https://www.ccab.org.uk/wp-content/uploads/2022/05/1_AMLGAS-Final-May-2022.pdf" target="_blank" download class="btn btn-primary btn-download">Download AML Guidance</a></p>
    </div>
</template>
<script>
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {BLink} from 'bootstrap-vue'
export default {
    components: {
        BLink,
        VuexyLogo
    }
}
</script>
<style lang="scss">
    .rt-wraper{padding:30px 40px;display:flex;flex-direction:column;}
    .rt-wraper .logo{align-self:flex-start;margin-bottom:20px;}
    .rt-wraper .logo svg{height:43px;width:160px;}
    .rt-wraper h1{margin:0 0 20px;color:#222;font-size:24px;font-weight:600;}
    .rt-wraper ol{color:#222;}
    .rt-wraper ol li{color:#222;margin-bottom:15px;font-size:18px;line-height:26px;color:#222;font-weight:600;}
    .rt-wraper ol li ol{color:#222;}
    .rt-wraper ol li ol li{color:#222;margin-bottom:10px;font-size:16px;line-height:26px;font-weight:400;}
    .rt-wraper .btn-download{align-self:flex-start;}
</style>
<style lang="scss">
    @import '@core/scss/vue/pages/page-auth.scss';
</style>